(function () {
    'use strict';
    $(document).ready(function () {

        // SOCIAL SHARING
        const $share = $('#share');
        $share.css('opacity', 1);
        $share.jsSocials({
            showLabel: false,
            showCount: false,
            shareIn: "popup",
            text: document.title,
            url: window.location.href,
            shares: [
                {
                    share: "facebook",
                    logo: `${$share.attr('data-templateURL')}/dist/images/facebook.svg`,
                    inlineSvg: true,
                },
                {
                    share: "twitter",
                    logo: `${$share.attr('data-templateURL')}/dist/images/twitter.svg`,
                    inlineSvg: true,
                    via: "pgnig",
                },
                {
                    share: "linkedin",
                    logo: `${$share.attr('data-templateURL')}/dist/images/linkedin.svg`,
                    inlineSvg: true,
                }
            ]
        });


        //tools show/hide
        $('#tools-toggle').click(function () {
            $('.tools-list').animate({
                height: 'toggle'
            });
            $(this).toggleClass('opened');
        });

        // accordion scroll animation
        $('.subpage .accordion.scroll-animation .collapse, .subpage .accordion .collapse').on('shown.bs.collapse', function (e) {
            updateTableSize();
            var positionTop = $(this).parent().offset().top; // - $('header').height();
            $('html, body').animate({
                scrollTop: positionTop
            }, 500);
            $(window).resize();
        });

        // responsive image enlarging
        $('.popup-trigger').click(function (e) {
            $(this).parent().find('.popup-image-container').fadeIn();
            e.preventDefault();
            return false;
        });

        $('.popup-image-container').click(function () {
            $(this).fadeOut();
        });

        //Tooltipster

        // default tooltips
        $('.default-tooltipster').tooltipster({
            maxWidth: 290,
            contentAsHTML: true,
            interactive: true,
            functionPosition: function (instance, helper, position) {
                position.coord.left = helper.geo.origin.offset.left - 20;
                return position;
            },
        });

        function updateTableSize() {
            $('.table-container').each(function () {
                if ($(this).parent().hasClass('panel-body')) return;
                var containerWidth = $(this).width();
                var tableWidth = $('table', this).width();

                if (tableWidth > containerWidth) {
                    $(this).addClass('scrollable');
                    $(this).mCustomScrollbar({
                        axis: "x",
                        mouseWheel: {
                            enable: true
                        }
                    });
                } else {
                    $(this).removeClass('scrollable');
                    $(this).mCustomScrollbar('destroy');
                }
            });
        }

    });
}(jQuery));
