(function () {
    'use strict';
    $(document).ready(function () {

        $(".play-video").modalVideo({
            channel: 'vimeo'
        });

        var swiperCta = new Swiper('.swiper-cta', {
            loop: true,
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
        });

        var swiperArticles = new Swiper('.swiper-articles', {
            loop: true,
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next',
            },
            slidesPerView: 3,
            spaceBetween: 20,
            breakpoints: {
                992: {
                    slidesPerView: 2,
                },
                576: {
                    slidesPerView: 1,
                }
            }
        });

    });
}(jQuery));
