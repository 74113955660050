(function () {
    'use strict';
    $(document).ready(function () {

        // Smooth scroll to
        $('.scroll-animation').on('click', function (e) {
            e.preventDefault();

            const href = $(this).attr('href');
            if (href) {
                $("html, body").animate({
                    scrollTop: $(href).offset().top
                }, 1000);
            }
        });

        $('.scroll-animation a').on('click', function (e) {
            e.stopPropagation();
        });

        //Menu mobile toggle
        $(".menu-toggle").click(function(){
            $('#site-menu').fadeToggle();
            $(this).toggleClass("open");
        });

        //rodo links
        $('.rodo-links-toggle').on('click', function () {
            $(this).toggleClass('open');
            $('.footer .rodo-links-list').fadeToggle();
        });

        //Search trigger
        $('.search-trigger').click(function () {
            $(this).toggleClass('active');
            $('.search-panel').fadeToggle('active');
            return false;
        });

    });
}(jQuery));
